<template>
  <main class="govuk-main-wrapper">
    <slot />
  </main>
</template>

<script>
export default {
  name: "GovMainWrapper"
};
</script>
